/** @format */

export function Syd() {
  return (
    <>
      <p className="p-11">
        I know this isn't on the stack of real cards you got, but I feel this be here is more fitting in some ways.
		<br />
        <br />
		I feel like often times the thing that brings people together is mutual hardship. People who share
		a mutual struggle always seem to come out of it with a special kind of bond. I'm sure you see where
		I'm going with this but I feel like for us that's the hallmark of our friendship. 
		Gao, Ciccio, and Robb all tried to do us in but we stuck it out together. 
		And I think in those hard times we managed to find some joy. 
		<br />
        <br />
		Not to say there haven't also been good times of course, but just about anyone can have fun together. 
		It takes a special kind of friendship to be miserable *and* have fun together. 
		<br />
        <br />
		Our trio the best part of college for me and in large part made it the best time of my life. 
		I wish we could've had it in Orlando, but unfortunately things just didn't shake out how they needed to.
		I miss seeing you more than you know and whenever we spend time togehter it always feels like we were never apart 
		at all. 
		<br />
        <br />
		Thank you for inviting me to Nashville, and I can't wait to see you again. (I imagine 
		it'll be for an Alan + us Disney trip.) 
		<br />
        <br />
		{"Love you <3"}
		<br />
		(and sorry this is late.)
      </p>
    </>
  );
}
