/** @format */

import React from "react";

export function PrivacyPolicy() {
  return (
    <>
      <div
        className="bg-stone-200 h-screen"
        style={{ backgroundAttachment: "fixed" }}
      >
        <div className="flex flex-col ">
          <div className="flex justify-center content-center py-12">
            <div className="bg-stone-100 border-4 border-stone-100 content-center w-9/12 rounded-lg shadow-lg py-24">
              <h1 className="text-3xl flex content-start px-24 ">
                Privacy Policy
              </h1>
              <br />
              <p className="px-24">
                Definitions:
                <br />
                <br /> Service. <br />
                ‘Service‘ means the website and/or the applications operated by
                John Perez.
                <br />
                <br />
                Personal Data.
                <br />
                ‘Personal data’ means any information relating to an identified
                or identifiable natural person (‘data subject’); an identifiable
                natural person is one who can be identified, directly or
                indirectly, in particular by reference to an identifier such as
                a name, an identification number, location data, an online
                identifier or to one or more factors specific to the physical,
                physiological, genetic, mental, economic, cultural or social
                identity of that natural person. Note: the Personal Data
                definition comes from the General Data Protection Regulation
                (GDPR).
                <br />
                <br />
                Information Collection And Use:
                <br />
                <br />
                The Service does not collect Personal Data.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
