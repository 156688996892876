/** @format */
import { Link } from "react-router-dom";
import React from "react";

export function Projects() {
	return (
		<div className="min-h-screen bg-gray-600 text-white w-full ">
			<div className="flex justify-center p-12">
				<div className="grid grid-cols-1 justify-center items-center gap-y-12 sm:w-2/5">
					<Link to={"/"}>
						<button className="underline">{"<--"}</button>
					</Link>
					<div>
						<a class="" href="shockwavegame.com">
							<h1 className="text-3xl flex content-start text-white underline underline-offset-1 hover:no-underline w-fit">
								SoundWave
							</h1>
						</a>
						<br />
						<p className="">
							I love rhythm games as a genre, and I've noticed that the hybrid
							rhythm genre has surprisingly few entries. Inspired by Hi-Fi Rush
							and BPM, I've decided to put my own spin on the genre that I've
							come to love.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
