/** @format */

import { Link } from "react-router-dom";

export function UsefulStuff() {
	return (
		<div className="min-h-screen bg-gray-600 text-white w-full ">
			<div className="flex justify-center p-12">
				<div className="grid grid-cols-1 justify-center items-center gap-y-6">
					<Link to={"/"}>
						<button className="underline">{"<--"}</button>
					</Link>
					<h1 className="text-2xl text-center">Useful Stuff</h1>

					<p className="text-xs">
						These items have had a notable impact on my life. Some in terms of
						productivity, others in general utility.
						<br /> Both lists are in order of usefulness.
					</p>

					<h1 className="text-xl font-bold">Hardware</h1>
					<div>
						<LinkItem
							url="https://www.yeti.com/drinkware/bottles/21071502686.html"
							title="Big Water Bottle"
							description={
								"You don't think you need a giant fuck off water bottle until you have a giant fuck off water bottle."
							}
						/>
						<LinkItem
							url=""
							title={"Bidet"}
							description={
								"This is taboo to talk about, but your life will be changed for the better. Not linking just go get one at home depot."
							}
						/>
						<LinkItem
							url="https://www.zsa.io/moonlander/"
							title="Moonlander Keyboard"
							description="If you're someone like me (vim user), then this keyboard is incredible. If you're not, then it's literally a hinderance."
						/>
						<LinkItem
							url="https://store.hermanmiller.com/home-office-chairs/embody-chair/4737.html?lang=en_US&sku=100137718"
							title="Herman Miller Embody"
							description="Think how much time you spend in your desk chair. It's worth it."
						/>
					</div>
					<h1 className="text-xl font-bold">Software</h1>
					<div>
						<LinkItem
							url="https://claude.ai/"
							title="Claude Pro"
							description="I used to use ChatGPT, but I've found that Claude is notably smarter."
						/>
						<LinkItem
							url="https://github.com/features/copilot"
							title="GitHub Copilot"
							description="AI code assistant that is smarter than me sometimes. Emphasis on the sometimes."
						/>
						<LinkItem
							url="https://ublockorigin.com/"
							title="Ad Blocker"
							description={"You're not a masochist. Get an ad blocker."}
						/>
						<LinkItem
							url="https://1password.com/"
							title="Literally Any Password Manager"
							description="I don't care which one you use. Just use one."
						/>
						<LinkItem
							url="https://tailwindcss.com"
							title={"Tailwind CSS"}
							description={"Library that lets me not write css."}
						/>
						<LinkItem
							url="https://timeryapp.com/"
							title="Timery"
							description={
								"Time tracking app. It's a good way to understand where your time is going."
							}
						/>
						<LinkItem
							url="https://github.com/VSCodeVim/Vim"
							title="VSCode Vim"
							description="Vim is super controversial. I'm on the side that thinks it's super worth learning."
						/>
						<LinkItem
							url="https://arc.net/"
							title="Arc Browser"
							description="This is the first innovation I've seen in the browser space in a long time."
						/>
						<LinkItem
							url="https://obsidian.md/"
							title={"Obsidian"}
							description={"Lightweight, nifty notes app."}
						/>
						<LinkItem
							url="https://plausible.io/"
							title="Plausible"
							description={
								"Plug and play web analytics that aren't invasive to a user's privacy."
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
//
function LinkItem({ url, title, description }) {
	return (
		<div className="">
			<a
				href={url}
				className="text-white underline underline-offset-1 hover:no-underline"
			>
				{title}
			</a>
			{description ? " - " + description : null}
		</div>
	);
}
