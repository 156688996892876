/** @format */

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./components/Home";
import { Projects } from "./components/Projects";
import { Writing } from "./components/Writing";
import { MyInsaneDay } from "./components/writing/MyInsaneDay";
import { Scammed } from "./components/writing/Scammed";
// import { College } from "./components/writing/College";
import { Resume } from "./components/Resume";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import { Mom } from "./components/writing/Mom";
import { Syd } from "./components/writing/Syd";
import { LinkGarden } from "./components/LinkGarden";
import { UsefulStuff } from "./components/UsefulStuff";

export default function App() {
	const posts = [
		// {
		// 	link: "/writing/college-thoughts",
		// 	title: "College Thoughts",
		// 	date: "April 2024",
		// 	element: <College />,
		// },
		{
			link: "/writing/my-insane-day",
			title: "My Insane Day",
			date: "April 2024",
			element: <MyInsaneDay />,
		},
		{
			link: "/writing/scammed",
			title: "Scammed",
			date: "May 2024",
			element: <Scammed />,
		},
	];
	return (
		<Router>
			<script
				defer
				src="https://cloud.umami.is/script.js"
				data-website-id="b74d70be-f7d8-40bd-ac2c-fa11803a84d5"
			></script>
			<div className="App">
				<Routes>
					<Route exact path="/" element={<Home />} />
					<Route exact path="/projects" element={<Projects />} />
					<Route path="resume" element={<Resume />} />
					<Route path="digital-garden" element={<LinkGarden />} />
					<Route path="useful-stuff" element={<UsefulStuff />} />
					<Route path="writing" element={<Writing posts={posts} />} />
					{/* writing */}
					{posts.map((post, index) => (
						<Route key={index} path={post.link} element={post.element} />
					))}
					{/* private */}
					<Route path="privacy-policy" element={<PrivacyPolicy />} />
					<Route path="mom" element={<Mom />} />
					<Route path="syd" element={<Syd />} />
				</Routes>
			</div>
		</Router>
	);
}
