/** @format */

import { Link } from "react-router-dom";
export function MyInsaneDay({}) {
	return (
		<div className="min-h-screen bg-gray-600 text-white w-full">
			<div className="flex justify-center p-12">
				<div className="flex flex-col p-6 gap-y-6 lg:w-2/5">
					<Link to={"/writing"}>
						<button className="underline">{"<--"}</button>
					</Link>
					<h1 className="text-2xl text-center">My Insane Day</h1>
					<div>
						I have a feeling I'm going to be telling a great many people about
						this, so I've decided it's probably best to write it down so that 1.
						I remember it as clearly as possible and 2. I can (selfishly) save
						myself the time of not retelling this story 100 times.
						<br />
						<br />
						This happened on 2024.04.19 around 3:00 PM.
						<br />
						<br />
						I decided that I was going to work from home for the rest of that
						day, so I walked back from the office to my apartment. Once there I
						got in the elevator and went to my floor. normal stuff.
						<br />
						<br />
						Upon exiting the elevator, I noticed a cop. And this wasn't just
						some guy with handcuffs in his back pocket. This was The Real Deal™.{" "}
						<button
							onClick={() =>
								window.open(
									"https://archives.fbi.gov/archives/fun-games/tools_of_the_trade/tools-of-the-trade-fbi-swat-text-version"
								)
							}
							className="underline"
						>
							Full tactical gear
						</button>
						. And 'cop' isn't exactly the right word, he was a full blown FBI
						agent.
						<br />
						<br />
						I had my headphones on and I blew right by him, didn't even say a
						word. Seeing that in real life was so far from the realm of
						possibility that I didn't even register it was real. I got about 5
						feet past him before it hit me and I decided to say something (or he
						might have said something to me that I missed). "Hey, is everything
						alright?" was met with "What apartment do you live in...?" I tried
						my best to look and sound as stupid as possible (though in that
						moment I probably didn't need to try) as I repeated back "uhhhh
						2000." I was genuinely unsure where I lived in that moment. As
						everyone I've ever known would tell you, I'm about as far from calm
						as you can get when the heat is turned up even slightly. I honestly
						still get nervous buying booze and I've been 21 for 2 years now. So
						with that, I turned the corner to go to my apartment and I am met
						with *20 FBI AGENTS* in the *exact* same get up. I'm talking full
						"breach and clear," climax of the movie getup. And they're all
						standing between me and my apartment. I walked down the hall like a
						POW walking into the prison for the first time. Once I got to the
						end of the hall, I was met with a question. "How well do you know
						your neighbor?" One of them asked. "He's seems a nice guy," I
						responded. "Well, he did something *very* bad." Oh. Cool, my next
						door neighbor is who they're after.
						<br />
						<br />
						So I went into my apartment. Over the next hour I heard some
						scuffles and I even saw some out on his balcony. Then, they all
						left.
						<br />
						<br />
						And that's the end of the story.
						<br />
						<br />
						BUT THEN you'll never believe who I catch out on his balcony at 9:30
						that night! This MF is just out there, being a normal, out-of-prison
						human as though he's one of us! (sorry readers in prison).
						<br />
						<br />
						I had to ask. I was dying to ask. How could you not ask about the
						FBI coming to his apartment. Is it a horrible, horrible idea? yes
						100%. Would you lie to your mom on the phone about it and say you
						didn't ask? yes. But did you ask? yes... To which I got the response
						"I have no idea what you're talking about."
						<br />
						<br />
						Cool. I then went back inside and continued my freak out.
						<br />
						<br />
						Fast forward and hour. I'm now on the phone with some friends
						telling them this story when there's a knock at the door. I go check
						it out and of course it's him, so I'm keeping that shit *locked*.
						"Heyyyy man what's up?" I said through the door. "Can we talk?" He
						replied. (I bet you're thinking this is the part where I get
						murdered. You know who else was having that thought? Me, at the
						time.).
						<br />
						<br />
						After doing an inspection through the peep hole and deciding that it
						probably *wasn't* in his best interest for his neighbor to die after
						having the FBI come to his house, I opened the door and there we
						were. Me and the most wanted man in that 5 foot radius. The rest of
						the conversation was actually quite boring. He apologized for "what
						I saw" and asked me a suspicious amount of questions about the exact
						details of the day, to which I responded pretty vaguely and
						constantly emphasized how much I did NOT want to get in his
						business.
						<br />
						<br />
						It seems like everything has mostly gone back to normal for the time
						being, though I'd be lying if I said that I'm not still freaking
						out.
						<br />
						<br />
						I'll leave deciding what he's up to as an exercise for the reader.
						<br />
						<br />
						Update: He's moved out of the complex. Who knows if he was arrested
						or fled
					</div>
				</div>
			</div>
		</div>
	);
}
