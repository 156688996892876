/** @format */
import { Link } from "react-router-dom";

export function Writing({ posts }) {
	return (
		<div className="min-h-screen bg-gray-600 text-white w-full">
			<div className="flex justify-center p-12">
				<div className="flex flex-col p-6 gap-y-6 lg:w-2/5">
					<Link to={"/"}>
						<button className="underline">{"<--"}</button>
					</Link>
					<h1 className="text-2xl text-center">Writing</h1>
					<div className="flex flex-col">
						{posts.map((post, index) => (
							<Link key={index} className="underline" to={post.link}>
								{post.title}
							</Link>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}
