/** @format */
import {
	NewspaperIcon,
	BookOpenIcon,
	ForwardIcon,
	ServerStackIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
const links = {
	worldview: [
		{
			url: "http://www.paulgraham.com/own.html",
			title: "Paul Graham - A Project of One's Own",
			type: "article",
		},
		{
			url: "https://paulgraham.com/noob.html",
			title: "Paul Graham - What You Can't Say",
			type: "article",
		},
		{
			url: "https://www.amazon.com/Whats-Our-Problem-Self-Help-Societies-ebook/dp/B0BTJCTR58",
			title: "Tim Urban - What's Our Problem",
			type: "book",
		},
		{
			url: "https://www.netflix.com/title/81254224",
			title: "The Social Dilemma",
			type: "video",
		},
		{
			url: "https://store.steampowered.com/app/972660/Spiritfarer_Farewell_Edition/",
			title: "Spiritfarer",
			description: "Game about dealing with death.",
			type: "game",
		},
		{
			url: "https://www.wnycstudios.org/podcasts/anthropocene-reviewed/episodes/anthropocene-reviewed-auld-lang-syne",
			title: "Anthropocene Reviewed - Auld Lang Syne",
			description:
				"I listen to this yearly on the day after Christmas. It's so easy to forget those we've loved.",
			type: "video",
		},
		{
			url: "https://www.youtube.com/watch?v=C25qzDhGLx8",
			title: "Why Die",
			type: "video",
		},
		{
			url: "https://www.youtube.com/watch?v=925wmb-4Yr4",
			title: "Philly Streets",
			description:
				"This is a non-hostile documentary on drug use in Philly. People are victims of their circumstances.",
			type: "video",
		},
	],
	selfImprovement: [
		{
			url: "https://www.julian.com/guide/muscle/intro",
			title: "Julian Shapiro - The Science of How to Build Muscle",
			type: "article",
		},
		{
			url: "https://www.amazon.com/Good-They-Cant-Ignore-You/dp/1455509124",
			title: "Cal Newport - So Good They Can't Ignore You",
			type: "book",
		},
		{
			url: "https://www.amazon.com/Deep-Work-Cal-Newport-audiobook/dp/B0189PVAWY/ref=sr_1_1?crid=1LP46BLA7FG9V&keywords=deep+work&qid=1676748669&s=books&sprefix=deep+work%2Cstripbooks%2C121&sr=1-1",
			title: "Cal Newport - Deep Work",
			type: "book",
		},
		{
			url: "https://www.youtube.com/watch?v=DkS1pkKpILY",
			title: "Andrew Huberman - What Alcohol Does to Your Body, Brain & Health",
			description: "aka why I don't drink",
			type: "video",
		},
	],
	niftySites: [
		{
			url: "https://weatherspark.com/",
			title: "Weather Spark",
			description: "Learn the year-round weather of any city on earth!",
			type: "",
		},
	],
	funStuff: [
		{
			url: "https://www.amazon.com/Way-Kings-Brandon-Sanderson/dp/0765365278",
			title: "The Stormlight Archive",
			description:
				"This was my intro into Brandon Sanderson's Cosmere. If you like fantasy, by all means give it a read.",
			type: "book",
		},
		{
			url: "https://store.steampowered.com/app/1237970/Titanfall_2/",
			title: "Titanfall 2",
			description:
				"I debated putting this on the list or not. I find this game so fun that I can't keep it installed on my computer without it consuming my life.",
			type: "game",
		},
		{
			url: "https://milan.cvitkovic.net/writing/things_youre_allowed_to_do/",
			title: "Things You're Allowed to Do",
			type: "article",
		},
		{
			url: "https://www.youtube.com/watch?v=rStL7niR7gs",
			title: "Rules for Rulers",
			type: "video",
		},
		{
			url: "https://isotropic.org/papers/chicken.pdf",
			title: "Chicken",
			type: "article",
		},
		{
			url: "https://www.amazon.com/Frank-Herberts-Dune-3-Book-Boxed/dp/0593201892/ref=sr_1_1?crid=FAU8M6AQ5SZG&dib=eyJ2IjoiMSJ9.tzsUUQm-lV61XLVgCxntIac83CU-firU0g16uXdnMXauzHKjOVyoJy0mE0fu2FArHqaxiVBKMA0pfdlVWtkbzYqJXBZQ7k65H_Icy__B_BcApFs5K0uqa-GbRIntt1YdH4W5CC6eZW7We0LZYcWquG1rsNxJOqjCI-yB9lZxaLl-FXlHMYUFZiTpg15w3rrwupTWT289uNFJsZJ_qqdrLbyRuDrj33RnJwMBwCGgq6s.7Ecf1ZjZA_amAS8ILyDSYetriYth5MYZ0BNlzifnFmg&dib_tag=se&keywords=dune&qid=1713722423&s=books&sprefix=dun%2Cstripbooks%2C91&sr=1-1",
			title: "Dune",
			description:
				"I highly recommend the first 2 Dune books. Zealousy is bad.",
			type: "book",
		},
	],
};

export const LinkGarden = () => {
	return (
		<div className="min-h-screen bg-gray-600 text-white w-full">
			<div className="flex justify-center p-12">
				<div className="grid grid-cols-1 justify-center items-center gap-y-6">
					<Link to={"/"}>
						<button className="underline">{"<--"}</button>
					</Link>
					<h1 className="text-2xl text-center">Digital Garden</h1>
					<p className="text-xs">
						These are pieces of media that I think are worth your time to
						experience. Some were profound, some were really interesting.
					</p>
					<h1 className="text-xl font-bold">
						If you click nothing here but one link, click this one:
					</h1>
					<LinkItem
						url={"https://waitbutwhy.com/2015/12/the-tail-end.html"}
						title="Tim Urban - The Tail End"
						description={"A sobering reminder of how short life is."}
						type={"article"}
					/>
					<h1 className="text-xl font-bold">Worldview:</h1>
					<div>
						{links.worldview.map((link, index) => (
							<LinkItem key={index} {...link} />
						))}
					</div>
					<h1 className="text-xl font-bold">Self Improvement:</h1>
					<div>
						{links.selfImprovement.map((link, index) => (
							<LinkItem key={index} {...link} />
						))}
					</div>
					<h1 className="text-xl font-bold">Nifty Websites:</h1>
					{links.niftySites.map((link, index) => (
						<LinkItem key={index} {...link} />
					))}

					<h1 className="text-xl font-bold">Fun Stuff:</h1>
					<div>
						{links.funStuff.map((link, index) => (
							<LinkItem key={index} {...link} />
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
function LinkItem({ url, title, description, type }) {
	const className = "w-4 h-4 text-inherit";
	const icons = {
		game: <ServerStackIcon className={className} />,
		book: <BookOpenIcon className={className} />,
		article: <NewspaperIcon className={className} />,
		video: <ForwardIcon className={className} />,
	};
	return (
		<div className="flex flex-row  gap-x-2 ">
			<div className="translate-y-1">{icons[type]}</div>
			<div className="">
				<a
					href={url}
					className="text-white underline underline-offset-1 hover:no-underline"
				>
					{title}
				</a>
				{description ? " - " + description : null}
			</div>
		</div>
	);
}
