/** @format */

export function Mom() {
  return (
    <>
      <p className="p-24">
        Writing Dad’s was so much easier. It feels like a lot of the things I’ve
        learned from him are all lessons that were taught. Whether it be
        learning how to try and charm others or learning how to ask questions,
        they feel like tangible skills. Some things I learned from him were
        never explicit lessons, but I picked up on things by watching him.
        <br />
        <br />
        The things I’ve taken from you are much different, and far less
        specific. I feel like I’ve learned a variety of skills from Dad, but
        from you… it feels like I’ve learned how to be kind.
        <br />
        <br />
        I’ve met a lot of people throughout college. And I’ve been faced with a
        great many situations and challenges. And I’ve also seen people faced
        with those same challenges, and struggle with things that I never even
        thought about. It feels like drama happens around me, as people complain
        about roommates or their friends, but I’ve never had that problem. Ever.
        My friends don’t cause drama (most of the time anyway) and it’s because
        of you that I know how to find friends that don’t. And I never cause
        drama, and it’s because of you that I don’t.
        <br />
        <br />
        And then there’s love. Often times I have doubts and worries. We all do.
        We’re human. We worry about school, we worry about work, we worry about
        family, we worry about friends. There will always be stress. Like Dad
        says, the only thing that never changes is change. But that’s not quite
        true. I know that you’ll always love me. Words cannot describe how true
        that is.
        <br />
        <br />
        That confidence means the world to me. To know that no matter what I do
        or where I go I’ll always have you there for me. Even during my worst
        times I’ve been able to go to you.
        <br />
        <br />
        I know this one isn’t as long or as Dad’s or whatever but I think that’s
        because our relationship is so much simpler. We’ve never had struggles
        or argued. I know I’m in a great spot with Dad now but that’s taken time
        and effort. With you I’ve always known, and that simplicity has made my
        life so much better.
        <br />
        <br />I love you Mom.
      </p>
    </>
  );
}
