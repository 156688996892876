/** @format */

import { Link } from "react-router-dom";
export function Scammed({}) {
	return (
		<div className="min-h-screen bg-gray-600 text-white w-full">
			<div className="flex justify-center p-12">
				<div className="flex flex-col p-6 gap-y-6 lg:w-2/5">
					<Link to={"/writing"}>
						<button className="underline">{"<--"}</button>
					</Link>
					<h1 className="text-2xl text-center">
						My Friend Got Scammed (maybe)
					</h1>
					<div>
						Currently, one of my good friends is crashing with me in Dallas.
						This is because he had a sublet agreement but the guy won't give him
						the keys. But here's the thing... the guy might actaully just be
						stupid, and not a scammer. <br /> Allow me to elaborate:
						<br />
						<br />
						About 2 months ago my friend found someone who had listed a sublet
						on Craig's List. It was a good deal and it made sense why the guy
						was subletting. He was shipping out of the country on a military
						operation at the end of April, and wanted some help with the rent
						while he wasn't there. My friend then asked me to go check out the
						place and make sure all was well.
						<br />
						So I got the guy's (James from now on) number and we set up a time.
						I went over and it was very normal. He was a super nice guy who let
						me in and walked me around. And him shipping out also made sense
						since the vast majority of his stuff was in storage. He pretty much
						just had a bed in there, which is how the ad described the place.
						<br />
						<br />
						Since everything checked out my friend paid a deposit and things
						were quiet for a few weeks. Here's where it gets a bit weird. A few
						weeks before move in, I texted James to get the keys. He ghosted for
						a while but then texted that I could pick them up that weekend. I
						was busy that weekend so I asked to move the day. Ghosted.
						<br />
						<br />
						After a few days of no response, I retexted. He said that he was
						deployed and that he gave the keys to his friend Joyce and that{" "}
						<i>she</i> would reach out to my friend and give him the keys. This
						was where my eyebrow began to ascend. Why would he not give my buddy
						Joyce's number. Why would he not say he was leaving.
						<br />
						<br />
						So, fast forward to the week before my friend arrives. Joyce texts
						(hallelujia) and says that she's reaching out to coordinate the key
						pickup. My friend tells here the date he arrives and she says that
						she got the date wrong and will be out of town then. Yikes.
						<br />
						<br />
						So, we call James and he says that he'll book a hotel for my friend
						to stay at. My friend found one that he can check into (he's under
						21, so options are limited), and sends it to James. Who then says
						he'll book it and then James proceeded to book a <i>
							different
						</i>{" "}
						hotel for 2 nights, then the one my friend wanted for the next 2
						nights. What? Anyway, my friend goes to attempt to check in, and
						hilariously the reason he couldn't was because it was booked with
						points and not real money. Only the person who booked it could check
						in because of that. So he drove back to my place in defeat and began
						crashing with me.
						<br />
						<br />
						Here's where things get <i>really</i> weird. Joyce texts my friend
						the day before she gets back and says that she'll be waiting at the
						apartment at 5:00 the next day to hand off the keys. She also
						mentions that she will be driving from Denver to Dallas in the
						middle of the night and will arrive in Dallas at 5 AM
						<br />
						<br />
						The next day, my friend calls Joyce around 4:45 to confirm. No
						pickup. He texts. No pickup. He calls again. No pickup. He calls
						James. No pickup. He calls James again. No pickup. He texts James.
						Still nothing.
						<br />
						<br />
						Since then it's been radio silence from both of them. So it{" "}
						<i>seems</i> like it was a scam all along. But there's so many
						questions. James could have ghosted literally the second he got
						paid. Instead he stayed in touch. If I had been available that
						weekend I could have really gone to his apartment. Joyce really did
						reach out. He really did book a hotel.
						<br />
						<br />
						So what's the deal? Is he just a really bad scammer? Or is he just
						really, really dumb.
						<br />
						<br />
						If he's not a scammer here's my pet theory as to what's happening:
						Joyce is dead. She drove back 12 hours in the middle of the night
						and got into a crash. It was also raining I believe, which supports
						this. James is in the military. He's deployed right now and doesn't
						have phone access. Therefore we will never get the keys. Or he's
						just a scammer. ¯\_(ツ)_/¯
					</div>
				</div>
			</div>
		</div>
	);
}
