/** @format */
import classNames from "classnames";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

function getMostRecentBirthday(dateOfBirth) {
	const today = new Date();
	let recentBirthday;

	if (
		today.getMonth() > dateOfBirth.getMonth() ||
		(today.getMonth() === dateOfBirth.getMonth() &&
			today.getDate() >= dateOfBirth.getDate())
	) {
		// If the current year's birthday has passed or is today
		recentBirthday = new Date(
			today.getFullYear(),
			dateOfBirth.getMonth(),
			dateOfBirth.getDate()
		);
	} else {
		// If the current year's birthday is yet to come
		recentBirthday = new Date(
			today.getFullYear() - 1,
			dateOfBirth.getMonth(),
			dateOfBirth.getDate()
		);
	}
	return recentBirthday;
}

function getWeeksAlive(dateOfBirth) {
	const currentDate = new Date();
	const recentBirthday = getMostRecentBirthday(dateOfBirth);
	const weeksOld = currentDate.getTime() - recentBirthday;
	const yearsOld = currentDate.getTime() - dateOfBirth.getTime();
	const weeks = Math.floor(weeksOld / (1000 * 60 * 60 * 24 * 7)); // Convert milliseconds to weeks
	const years = Math.floor(yearsOld / (1000 * 60 * 60 * 24 * 7 * 52));

	return years * 52 + weeks;
}

const LifeCalendar = ({ dateOfBirth }) => {
	const numYears = 90;
	const numWeeks = 52;
	const weeksAlive = getWeeksAlive(dateOfBirth);
	const [animated, setAnimated] = useState(false);

	useEffect(() => {
		const animateSquares = () => {
			const squares = document.querySelectorAll(".week-square");
			let baseDelay = 20; // Initial delay in milliseconds
			let prevDelay = 0;

			squares.forEach((square, index) => {
				console.log(prevDelay, baseDelay);
				if (index % (numWeeks * 2) === 0)
					baseDelay = Math.max(2, (baseDelay *= 3 / 4)); // Increase delay every year (52 weeks
				let delay = prevDelay + baseDelay;
				if (index < weeksAlive) {
					setTimeout(() => {
						square.classList.add("lived");
					}, delay);
				}
				prevDelay = delay;
			});
		};

		if (!animated) {
			animateSquares();
			setAnimated(true);
		}
	}, [animated, weeksAlive]);

	return (
		<div className="flex flex-col sm:gap-y-1 gap-y-[1px]">
			<div className="flex flex-row justify-between w-[96.55%]">
				{Array.from({ length: numWeeks }).map((_, idx) => (
					<div key={idx}>
						{idx % 5 === 0 && (
							<div className="text-sm">{idx === 0 ? idx + 1 : idx}</div>
						)}
					</div>
				))}
			</div>
			{Array.from({ length: numYears }).map((_, outerIdx) => (
				<div key={outerIdx} className="relative w-full">
					{outerIdx % 10 === 0 && (
						<div className="absolute -translate-x-5 -translate-y-[6px] text-sm">
							{outerIdx}
						</div>
					)}
					<div className="flex flex-row sm:gap-x-1 gap-x-[1px]">
						{Array.from({ length: numWeeks }).map((_, innerIdx) => (
							<WeekSquare
								key={`${outerIdx}-${innerIdx}`}
								lived={outerIdx * 52 + innerIdx < weeksAlive}
							/>
						))}
					</div>
				</div>
			))}
			<div className="">90</div>
		</div>
	);
};

export default LifeCalendar;

function LinkRow() {
	return (
		<div className="flex sm:flex-row flex-col  justify-between">
			<Link to={"/resume"}>
				<span className="underline">resume</span>
			</Link>
			<Link to={"/projects"}>
				<span className="underline">projects</span>
			</Link>
			<Link to={"/useful-stuff"}>
				<span className="underline">useful stuff</span>
			</Link>
			<Link to={"/digital-garden"}>
				<span className="underline">link garden</span>
				🌿
			</Link>
			<Link to={"/writing"}>
				<span className="underline">writing</span>
			</Link>
			<button
				onClick={() => window.open("https://github.com/jjperez9000")}
				className="underline flex items-start"
			>
				github
			</button>
		</div>
	);
}

export function Home() {
	const dateOfBirth = new Date("2000-11-20");
	const weeksAlive = getWeeksAlive(dateOfBirth);

	return (
		<div className="min-h-screen bg-gray-600 text-white flex justify-center w-full">
			<div className="flex flex-col p-6 gap-y-6">
				<div className="flex flex-col items-start justify-start">
					<div>John Perez </div>
					<div>Currently learning game dev.</div>
					<div>Been around for {weeksAlive} weeks.</div>
				</div>
				<LinkRow />
				<LifeCalendar dateOfBirth={dateOfBirth} />
				<div className="flex flex-col text-xs text-black gap-x-2 py-6">
					<div>
						inspired by
						<div>
							<a
								href="https://waitbutwhy.com/2015/12/the-tail-end.html"
								target="_blank"
								rel="noopener noreferrer"
								className="underline"
							>
								https://waitbutwhy.com/2015/12/the-tail-end.html
							</a>{" "}
							and
						</div>
						<div>
							<a
								href="https://www.bryanbraun.com/your-life/weeks.html"
								target="_blank"
								rel="noopener noreferrer"
								className="underline"
							>
								https://www.bryanbraun.com/your-life/weeks.html
							</a>
						</div>
					</div>
					<div>--</div>
					<div className="">memento mori</div>
				</div>
			</div>
		</div>
	);
}

function WeekSquare({ lived, event }) {
	return (
		<div
			className={classNames(
				"week-square border border-black sm:w-2 sm:h-2 w-[6px] h-[6px]",
				{
					"bg-green-300": lived,
				}
			)}
		></div>
	);
}
