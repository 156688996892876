/** @format */
import { Link } from "react-router-dom";
const resumeItems = [
	{
		title: "Engineering Analyst",
		location: "Arenova Capital, Dallas TX",
		date: "September 2023 - present",
		experience: [],
	},
	{
		title: "Software Engineer",
		location: "Lockheed Martin MFC, Orlando FL",
		date: "January 2023 - August 2023",
		experience: ["Embedded C++ dev for a large program", "[redacted]"],
	},
];

function ResumeItem({ title, location, date, experience }) {
	return (
		<div className="flex flex-col gap-y-2">
			<p className="text-lg">
				{title} | {location}
				<br />
				{date}
				<br />
			</p>
			<p className="text-sm">
				{experience.map((exp) => (
					<>
						- {exp}
						<br />
					</>
				))}
			</p>
			<br />
		</div>
	);
}

export function Resume() {
	return (
		<div className="min-h-screen bg-gray-600 text-white w-full ">
			<div className="flex justify-center p-12">
				<div className="grid grid-cols-1 justify-center items-center gap-y-4">
					<Link to={"/"}>
						<button className="underline">{"<--"}</button>
					</Link>
					<h1 className="text-2xl lg:text-3xl flex content-start ">
						Education
					</h1>
					<div className="flex flex-col">
						<ResumeItem
							title={"B.S. Computer Science"}
							location={"Clemson University, Clemson SC"}
							date={"August 2019 - December 2022"}
							experience={["4.0 GPA", "Summa Cum Laude", "Faculty Scholar"]}
						/>
					</div>
					<h1 className="text-2xl lg:text-3xl flex content-start ">
						Experience
					</h1>
					<div className="flex flex-col gap-y-5">
						{resumeItems.map((item, index) => (
							<ResumeItem
								key={index}
								title={item.title}
								location={item.location}
								date={item.date}
								experience={item.experience}
							/>
						))}
					</div>
					<h1 className="text-2xl lg:text-3xl flex content-start ">Skills</h1>
					Python, React, Embedded C/C++, Web Scraping, Giant Parqet Files
				</div>
			</div>
		</div>
	);
}
